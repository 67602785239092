<template>
	<div class="card mb-1" :class="index % 2 == 0 ? 'invert' : ''">
		<div class="card-body p-12">
			<h6 class="font-15 mb-1">
				<i class="far fa-eye me-2 font-13 color-theme" v-if="log.tipo == 'Aula visualizada'"></i>
				<i class="far fa-shopping-cart me-2 font-13 color-theme" v-else-if="log.tipo == 'Compra'"></i>
				<i class="far fa-quote-right me-2 font-13 color-theme" v-else></i>
				<span>{{ log.descricao }}</span>
			</h6>
			<p class="font-12 limitador text-secondary mb-0">{{ formatDateTime(log.data) }}</p>
		</div>                           
	</div>
</template>

<script>

export default {
	name: 'Log',
   props: ['log', 'index'],
	methods: {
		formatDateTime(data) {
			const { hour, minute, second, dayOfMonth, month, year } = data;

			const formattedHour = String(hour).padStart(2, '0');
			const formattedMinute = String(minute).padStart(2, '0');
			const formattedSecond = String(second).padStart(2, '0');

			const formattedDayOfMonth = String(dayOfMonth).padStart(2, '0');

			const formattedTime = `${formattedHour}:${formattedMinute}:${formattedSecond}`;
			const formattedDate = `${formattedDayOfMonth}/${this.getMonthNumber(month)}/${year}`;

			return `${formattedDate} - ${formattedTime}`;
		},
		getMonthNumber(month) {
			const months = {
			JANUARY: '01',
			FEBRUARY: '02',
			MARCH: '03',
			APRIL: '04',
			MAY: '05',
			JUNE: '06',
			JULY: '07',
			AUGUST: '08',
			SEPTEMBER: '09',
			OCTOBER: '10',
			NOVEMBER: '11',
			DECEMBER: '12'
			};

			return months[month];
		}
	}
}

</script>

<style scoped>
.lixoIcon {
   color: rgb(165, 57, 57);
   opacity: 0;
   transition: opacity 0.5s;
}

.cardEscuro {
   background: var(--border-color);
}
</style>